














































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

import QuestionItem from '@/partials/components/QuestionItem.vue'
import CandidatesPerformanceTable from '@/partials/tables/exams/finished/CandidatesPerformance.vue'

@Component({
  components: {
    QuestionItem,
    CandidatesPerformanceTable
  }
})
export default class ExamsFinishedReport extends Vue {
  examReport = {
    scheduledTo: '',
    services: [],
    serviceUsers: [],
    exam: {
      examQuestions: []
    },
    examCandidateResultsGraph: [],
    examCandidateResults: []
  }

  done = false

  created () {
    axios.get(`exam/${this.$route.params.examId}/schedule/${this.$route.params.scheduleId}`)
      .then(response => {
        this.examReport = camelCaseKeys(response.data.data, { deep: true })
        this.donutChart.data = this.examReport.examCandidateResultsGraph
      })
      .finally(() => { this.done = true })
  }

  donutChart = {
    data: [],
    options: {
      title: '',
      resizable: true,
      legend: {
        alignment: 'center'
      },
      donut: {
        alignment: 'center'
      },
      height: '400px'
    }
  }
}
