






















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import View16 from '@carbon/icons-vue/es/view/16'

type Tab = {
  label: string;
  query: string;
}

@Component({
  components: {
    View16
  }
})
export default class CandidatesPerformanceTable extends Vue {
  @Prop() candidates!: Array<ExamCandidateResult>
  @Prop({ required: true, type: Boolean }) done!: boolean
  filteredData: Array<ExamCandidateResult> = []
  data: Array<ExamCandidateResult> = []

  tabs: Array<Tab> = [
    {
      label: 'Pendentes',
      query: 'pend'
    },
    {
      label: 'Aprovados',
      query: 'pass'
    },
    {
      label: 'Reprovados',
      query: 'fail'
    },
    {
      label: 'Eliminados',
      query: 'kill'
    }
  ]

  @Watch('candidates', { immediate: true })
  onCandidatesChange (newVal: Array<ExamCandidateResult>) {
    this.data = newVal
    this.filterCandidates()
  }

  statusPrettyName (candidate: ExamCandidateResult) {
    const prettyName = {
      pend: 'Pendente',
      pass: 'Aprovado',
      fail: 'Reprovado',
      kill: 'Eliminado'
    }

    return prettyName[candidate.status]
  }

  selectedTab: Tab = {
    label: 'Pendentes',
    query: 'pend'
  }

  tabChange (tab: number) {
    const route = this.tabs[tab]
    this.selectedTab = route

    this.filterCandidates()
  }

  filterCandidates () {
    const filterStatus = this.selectedTab.query

    this.filteredData = this.data.filter(candidate => candidate.status === filterStatus)
  }
}
